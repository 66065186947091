<template>
    <div class="add-new-delivery">
        <title-bar
            submitBtn
            :submitBtnTitle="$t('messages.deliveryCreate')"
            :title="$t('messages.deliveryCreate')"
            @submitPressed="submitPressed"
        />
        <delivery-form :deliveryObject="delivery" :action="action" @clearAction="action = null"/>
    </div>
</template>

<script>
import TitleBar from '@/components/TitleBar.vue'
import DeliveryForm from './components/DeliveryForm.vue'

export default {
    components: {
        TitleBar,
        DeliveryForm
    },
    data() {
        return {
            action: null,
            delivery: {}
        }
    },
    methods: {
        submitPressed() {
            this.action = 'add'
        }
    }
}
</script>
